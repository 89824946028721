import React from "react";
import { BgSymbol, BgSymbolContainer } from "./SymbolBg.styles";

const symbols = [
  { x: -1, y: 10, delay: 0, id: "social-at" },
  { x: 5, y: 20, delay: 600, id: "social-bookmark" },
  { x: 85, y: 3, delay: 880, id: "social-camera" },
  { x: 77, y: 35, delay: 200, id: "social-cheers" },
  { x: 76, y: 1, delay: 500, id: "social-comment" },
  { x: 86, y: 24, delay: 0, id: "social-commentcount" },
  { x: 79, y: 13, delay: 200, id: "social-follower" },
  { x: 75, y: 20, delay: 50, id: "social-happy" },
  { x: 86, y: 14, delay: 600, id: "social-hashtag" },
  { x: 23, y: 0, delay: 600, id: "social-heart" },
  { x: 9, y: 4, delay: 250, id: "social-heart2" },
  { x: 11, y: 44, delay: 600, id: "social-home" },
  { x: 14, y: 12, delay: 35, id: "social-laugh" },
  { x: 93, y: 8, delay: 900, id: "social-like" },
  { x: 4, y: 33, delay: 600, id: "social-like" },
  { x: 95, y: 20, delay: 1100, id: "social-love" },
  { x: 11, y: 30, delay: 265, id: "social-profile" },
  { x: 5, y: 20, delay: 250, id: "social-surprise" },
  { x: 91, y: 32, delay: 1125, id: "facebook-color" },
  { x: 18, y: 35, delay: 245, id: "twitter-color" },
  { x: 20, y: 22, delay: 325, id: "instagram-color" },
];

const SymbolBg = () => {
  return (
    <BgSymbolContainer>
      {symbols.map((symbol, index) => (
        <BgSymbol
          key={index}
          maxWidth={30}
          maxHeight={30}
          id={symbol.id}
          style={{
            left: symbol.x + "%",
            top: symbol.y + "%",
            animationDelay: symbol.delay + "ms",
          }}
        />
      ))}
    </BgSymbolContainer>
  );
};

export default SymbolBg;
