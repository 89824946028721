// @flow
import React, { useEffect, useState } from "react";
import Base from "./Base";
import { colors } from "components/styles/variables";
import SqueezeHeader from "./header/SqueezeHeader";

import { StripeProvider } from "react-stripe-elements";

type PropType = {
  children?: React$Node,
};

const SqueezPage = (props: PropType) => {
  const [stripe, setStripe] = useState(null);
  useEffect(() => {
    if (window && window.Stripe) {
      setStripe(window.Stripe(process.env.GATSBY_STRIPE_PK_KEY));
    }
  }, []);
  return (
    <Base
      header={<SqueezeHeader />}
      styleResetProps={{ bgColor: colors.grayLight }}
      content={
        <StripeProvider stripe={stripe}>{props.children}</StripeProvider>
      }
    />
  );
};

export default SqueezPage;
