// @flow
import styled, { css } from "styled-components";
import {
  spacing,
  colors,
  mediaQueries,
  borderRadius,
  boxShadow,
  fontSize,
} from "components/styles/variables";

import { H2, H3, H4, H5, H6 } from "components/styles/type";

import { UtilsClasses } from "components/styles/utils";

export const PlanContainer = styled.div``;

export const PlanButton = styled.button`
  display: block;
  border: 5px solid
    ${props => (props.isSelected ? colors.primary : colors.grayLight)};
  border-radius: ${borderRadius.base}px;
  background: transparent;
  width: 100%;
  text-align: left;
  padding: ${spacing.base}px;
  cursor: pointer;
  min-height: 400px;
  transition: background ease 250ms, border ease 250ms;

  &:hover,
  &:focus {
    outline: none;
    background: #fff8f8;
  }
`;

export const PlanName = styled(H6)`
  color: ${colors.primary};
  margin-bottom: ${spacing.medium}px;
`;

export const PlanRadioButton = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid
    ${props => (props.isSelected ? colors.primary : colors.gray)};
  margin-right: ${spacing.xsmall}px;
  vertical-align: middle;
  border-radius: 100%;
  background-color: ${props =>
    props.isSelected ? colors.primary : colors.white};
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: white;
    border-radius: 100%;
    height: 10px;
    width: 10px;
  }
`;

export const PlanTitle = styled(H2)`
  /* text-align: center; */
  margin-bottom: ${spacing.xsmall}px;

  ${props => UtilsClasses(props)}
`;

export const PlanSubtitle = styled(H5)`
  /* text-align: center; */
  color: ${colors.grayDark};

  ${props => UtilsClasses(props)}
`;

export const PlanDetails = styled.ul`
  /* text-align: center; */
  margin: 0;
  list-style: none;
  padding: ${spacing.medium}px 0;
  flex: 1;
  /* font-size: ${fontSize.h6}px; */

  ${props => UtilsClasses(props)}

  li {
    margin-bottom: ${spacing.xsmall}px;
  }
`;

export const PlanPrice = styled(H3)`
  margin: 0;

  ${props => UtilsClasses(props)}
`;

export const PlanOldPrice = styled.span`
  color: ${colors.yellow};
  text-decoration: line-through;
`;

export const PlanPriceDetails = styled(H4)`
  margin: 0;
  color: ${colors.yellow};
  ${props => UtilsClasses(props)}
`;

export const PlanMeta = styled.div`
  color: ${colors.primary};
  padding: ${spacing.xsmall}px;
  margin-bottom: ${spacing.small}px;
  min-height: 50px;

  ${props => UtilsClasses(props)}
`;

export const PlanPriceDetailsCaption = styled.div`
  color: ${colors.grayDark};
`;

export const PlanPricingLarge = styled.div`
  ${mediaQueries.smMin`
    display: flex;
    align-items: center;
  `}
`;

export const PlanDetailsLarge = styled.div`
  ${mediaQueries.smMin`
    display: flex;
  `}
`;
