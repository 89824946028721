//@flow
import axios from "axios";
import type { AxiosPromise } from "axios";

// import Firebase from "../../firebase/firebase.config";

export const CLOUDINARY_NAME = "hotfix";
export const CLOUDINARY_PRESET = "smm_unsigned";

type CloudinaryResType = {
  access_mode: string,
  bytes: number,
  created_at: "2019-03-26T06:33:39Z",
  etag: string,
  format: "jpg" | string,
  height: number,
  placeholder: boolean,
  public_id: string,
  resource_type: "image" | string,
  secure_url: string | "https",
  signature: string,
  tags: [],
  type: "upload" | string,
  url: string | "http",
  version: number,
  width: number,
};

const uploadToCloudinary = (
  img: string | File
): AxiosPromise<CloudinaryResType> => {
  // const user = Firebase.auth.currentUser;
  const userID = "smm"; //user.uid;
  let formData = new FormData();

  formData.append("file", img);
  formData.append("folder", userID);
  formData.append("upload_preset", CLOUDINARY_PRESET);

  return axios({
    url: `https://api.cloudinary.com/v1_1/${CLOUDINARY_NAME}/upload`,
    method: "POST",
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: formData,
  });
};

export default uploadToCloudinary;
