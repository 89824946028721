// @flow
import React from "react";
import { Container } from "components/styles/layout";
import { colors, spacing } from "components/styles/variables";
import {
  SqueezeHeaderStyle,
  TagLine,
  HeaderSymbol,
} from "./squeezeHeader.styles";
import { H5 } from "components/styles/type";

const SqueezeHeader = () => {
  return (
    <SqueezeHeaderStyle>
      <Container>
        <HeaderSymbol id="logo-text" fill={colors.primary} />
        <TagLine>
          <H5 marginNone>An Identity & Development Studio</H5>
        </TagLine>
      </Container>
    </SqueezeHeaderStyle>
  );
};

export default SqueezeHeader;
