// @flow
import React from "react";
import { Lead, Em, Caption } from "components/styles/type";
import { Section } from "./SMM.styles";
import { Row, Col } from "components/styles/layout";
import Symbol from "components/symbols/Symbol";
import { UtilDiv } from "components/styles/utils";
import Fade from "react-reveal/Fade";

const Welcome = () => {
  return (
    <Section>
      <Row>
        <Col sm6>
          <Fade bottom delay={400} distance="30%">
            <Lead>
              Welcome to our <Em>limited time offer</Em> of free social media
              marketing for your business with no strings attached. It’s quick,
              easy, and 100% free, read below to see how it all works.
            </Lead>
          </Fade>
        </Col>
        <Col sm6>
          <Fade bottom delay={400} distance="30%">
            <UtilDiv
              textCenter
              style={{ marginBottom: 40, maxWidth: 380, margin: "0 auto" }}
            >
              <div style={{ marginBottom: 20, marginTop: 20 }}>
                <Symbol
                  maxHeight={35}
                  maxWidth={35}
                  style={{ marginRight: 20 }}
                  id="facebook-color"
                />
                <Symbol
                  maxHeight={35}
                  maxWidth={35}
                  style={{ marginRight: 20 }}
                  id="instagram-color"
                />
                <Symbol maxHeight={35} maxWidth={35} id="twitter-color" />
              </div>

              <Caption>
                We create a schedule, content, and then post on your Twitter,
                Facebook, and Instagram accounts.
              </Caption>
            </UtilDiv>
          </Fade>
        </Col>
      </Row>
    </Section>
  );
};

export default Welcome;
