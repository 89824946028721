// @flow
import React from "react";
import { Label, FormGroup, ErrorMessage } from "components/styles/form";
import { Caption } from "components/styles/type";
import { Field } from "react-final-form";
import camelCase from "components/utils/camelCase";

type PropType = {
  label?: string,
  name?: string,
  component: any,
  placeholder?: string,
  formGroupStyle?: any,
  type?: string,
  helper?: string,
};

const InputField = ({
  label,
  name,
  placeholder,
  component: InputComponent,
  formGroupStyle,
  type,
  helper,
}: PropType) => {
  if (!name) name = camelCase(label);
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <FormGroup style={formGroupStyle}>
          {label && (
            <div>
              <Label htmlFor={name}>{label}</Label>
            </div>
          )}
          <InputComponent
            {...input}
            placeholder={placeholder}
            id={name}
            type={type}
            style={{ marginBottom: 10 }}
          />
          {meta.touched && meta.error ? (
            <ErrorMessage>{meta.error}</ErrorMessage>
          ) : null}
          {helper && <Caption>{helper}</Caption>}
        </FormGroup>
      )}
    </Field>
  );
};

export default InputField;
