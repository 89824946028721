// @flow

import type { SmmSubmissionDetailsType } from "./SMM.types";

const validate = (
  values: SmmSubmissionDetailsType,
  serverChecks: void | boolean
) => {
  const errors: SmmSubmissionDetailsType = {};
  if (!values.logoFile || values.logoFile === "@error/nofile") {
    errors.logoFile = "Missing Logo File";
  }
  if (values.logoFile && values.logoFile === "@error/wrongformat") {
    errors.logoFile = "Please select a PNG or JPEG file";
  }
  if (!values.businessName) {
    errors.businessName = "A business name is required";
  }
  if (!values.description) {
    errors.description = "A short description of your business is required";
  }
  if (!values.industry) {
    errors.industry = "Please choose or type an industry for your business";
  }
  if (!values.foundingDate) {
    errors.foundingDate = "Founding Date is required";
  }
  if (!values.website) {
    errors.website = "A valid website address is required";
  }
  if (!values.address) {
    errors.address = "A valid address is required";
  }
  if (!values.primaryColor) {
    errors.primaryColor = "A primary brand color is required";
  }
  if (!values.contactFirstName) {
    errors.contactFirstName = "Your first name is missing";
  }
  if (!values.contactLastName) {
    errors.contactLastName = "Your last name is missing";
  }
  if (!values.contactEmail) {
    errors.contactEmail = "Your email is missing";
  }
  if (!values.contactPhone) {
    errors.contactPhone = "Your phone is missing";
  }

  if (!values.billingCycle) {
    errors.billingCycle = "A billing cycle choice is requried";
  }

  if (serverChecks && values.planOption && values.planOption !== "freeYear") {
    // check for a token on the server
    if (!values.token || !values.token.id) {
      errors.token = "Token is missing";
    }
  }

  return errors;
};

export default validate;
