// @flow
import React from "react";
import Downshift from "downshift";
// import matchSorter from "match-sorter";
import {
  Label,
  FormGroup,
  InputText,
  DropdownOptions,
  DropdownOption,
  DropdownToggleBtn,
  ErrorMessage,
} from "components/styles/form";
import { Caption } from "components/styles/type";
import Symbol from "components/symbols/Symbol";
const itemToString = item => (item ? item : "");

type PropType = {
  label?: string,
  placeholder?: string,
  helper?: string,
  input: {
    name: string,
    value: string,
  },
  meta: {
    active: boolean,
    data: {},
    dirty: boolean,
    dirtySinceLastSubmit: boolean,
    invalid: boolean,
    modified: boolean,
    pristine: boolean,
    submitFailed: boolean,
    submitSucceeded: boolean,
    submitting: boolean,
    touched: boolean,
    valid: boolean,
    visited: boolean,
  },
};

const InputDropdown = ({
  input,
  meta,
  placeholder,
  items,
  label: fieldLabel,
  helper,
}) => (
  <Downshift
    {...input}
    onInputValueChange={inputValue => {
      input.onChange(inputValue);
    }}
    itemToString={itemToString}
    selectedItem={input.value}
  >
    {({
      getInputProps,
      getItemProps,
      getLabelProps,
      openMenu,
      isOpen,
      // inputValue,
      highlightedIndex,
      selectedItem,
      clearSelection,
      getToggleButtonProps,
    }) => {
      // const filteredItems = matchSorter(items, inputValue, {
      //   keys: ["label"],
      //   maxRanking: matchSorter.rankings.STARTS_WITH,
      // });
      return (
        <div>
          <FormGroup>
            <div>
              <Label {...getLabelProps()}>{fieldLabel}</Label>
            </div>

            <div style={{ position: "relative" }}>
              {selectedItem ? (
                <DropdownToggleBtn
                  onClick={clearSelection}
                  aria-label="clear selection"
                >
                  <Symbol id="close" style={{ verticalAlign: "top" }} />
                </DropdownToggleBtn>
              ) : (
                <DropdownToggleBtn {...getToggleButtonProps()}>
                  <Symbol id="dropdown" style={{ verticalAlign: "top" }} />
                </DropdownToggleBtn>
              )}

              <InputText
                onFocus={openMenu}
                {...getInputProps({
                  name: input.name,
                  placeholder,
                })}
                style={{ marginBottom: 10 }}
              />
            </div>
            {helper && <Caption>{helper}</Caption>}
            {meta.touched && meta.error ? (
              <ErrorMessage>{meta.error}</ErrorMessage>
            ) : null}
            {isOpen && !!items.length && (
              <DropdownOptions>
                {items.map(({ value }, index) => (
                  <DropdownOption
                    key={value}
                    {...getItemProps({
                      key: value,
                      index,
                      item: value,
                    })}
                    highlight={highlightedIndex === index}
                    selected={selectedItem === value}
                  >
                    {value}
                  </DropdownOption>
                ))}
              </DropdownOptions>
            )}
          </FormGroup>
        </div>
      );
    }}
  </Downshift>
);

export default InputDropdown;
