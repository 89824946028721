// @flow
import type { PlanType } from "./PlanSelection.types";

const planOptions: { [id: string]: PlanType } = {
  freeYear: {
    id: "freeYear",
    name: "Free Year Plan",
    title: "1 Post a Month",
    subtitle: "12 Total",
    planDetails: [
      "12 Month Schedule",
      "Custom Post Content (copy and graphics)",
      "Ability to Edit Content Before Approval",
    ],
    pricing: {
      monthly: {
        detail: "",
        old: "",
        cost: "Free",
        savings: "",
        chargeCost: 0,
        stripeProductID: "",
        stripePlanID: "",
      },
      annually: {
        detail: "",
        old: "",
        cost: "Free",
        savings: "",
        chargeCost: 0,
      },
    },
  },
  silverPlan: {
    id: "silverPlan",
    name: "Silver Plan",
    title: "4 Posts a Month",
    subtitle: "48 Total - Avg. 1/week",
    planDetails: [
      "All of the Free Year Plan’s offerings",
      "PLUS - Ability to Edit Content After Approval",
    ],
    pricing: {
      monthly: {
        detail: "Spring Sale - 10% Off",
        old: "$99",
        cost: "$89/m",
        savings: "Save $178. 2 FREE months when billed annually.",
        chargeCost: 8900,
        stripeProductID: process.env.GATSBY_STRIPE_SILVER_PROD_ID,
        stripePlanID: process.env.GATSBY_STRIPE_SILVER_PLAN_ID,
      },
      annually: {
        detail: "Spring Sale - 10% Off",
        old: "$990",
        cost: "$890",
        savings: "You're saving $178.",
        chargeCost: 89000,
      },
    },
  },
  goldPlan: {
    id: "goldPlan",
    name: "Gold Plan",
    title: "8 Posts a Month",
    subtitle: "96 Total - Avg. 2/week",
    planDetails: [
      "All of the Silver Plan’s offerings",
      "PLUS - Monthy Report on Posting Statistics",
    ],
    meta: "Recommended",
    pricing: {
      monthly: {
        detail: "Spring Sale - 25% Off",
        old: "$212",
        cost: "$159/m",
        savings: "Save $318. 2 FREE months when billed annually.",
        chargeCost: 15900,
        stripeProductID: process.env.GATSBY_STRIPE_GOLD_PROD_ID,
        stripePlanID: process.env.GATSBY_STRIPE_GOLD_PLAN_ID,
      },
      annually: {
        detail: "Spring Sale - 25% Off",
        old: "$2,120",
        cost: "$1,590",
        savings: "You're saving $318.",
        chargeCost: 159000,
      },
    },
  },
  completeMarketingTeam: {
    id: "completeMarketingTeam",
    name: "Complete Marketing Team Plan",
    title: "8 Posts a Month + Marketing Team",
    subtitle: "96 Total - Average 2/week",
    planDetails: [
      "All of the Gold Plan’s offerings",
      "PLUS - Team managing your media accounts",
      "PLUS - Engaging with your followers (responding to questions, liking comments, etc.)",
      "PLUS - Monthly engagement activities (Quizzes, polls, giveaways, etc.)",
      "PLUS - Monthly reports on engagement activities",
      "PLUS - Monthly adjustments to marketing campaigns",
    ],
    pricing: {
      monthly: {
        detail: "Spring Sale - 25% Off",
        old: "$212",
        cost: "$159/m",
        savings: "Save $318. 2 FREE months when billed annually.",
        chargeCost: 15900,
        stripeProductID: process.env.GATSBY_STRIPE_GOLD_PROD_ID,
        stripePlanID: process.env.GATSBY_STRIPE_GOLD_PLAN_ID,
      },
      annually: {
        detail: "Spring Sale - 25% Off",
        old: "$2,120",
        cost: "$1,590",
        savings: "You're saving $318.",
        chargeCost: 159000,
      },
    },
  },
};

export default planOptions;
