// @flow
import React, { useState } from "react";
import { Row, Col } from "components/styles/layout";
import { Section } from "./SMM.styles";
import { H2, H6 } from "components/styles/type";
import type { PlanType } from "./PlanSelection.types";
import Symbol from "components/symbols/Symbol";
import Fade from "react-reveal/Fade";
import { Field } from "react-final-form";

import planOptions from "./planOptions";

import {
  PlanContainer,
  PlanButton,
  PlanTitle,
  PlanSubtitle,
  PlanDetails,
  PlanPrice,
  PlanPriceDetails,
  PlanName,
  PlanRadioButton,
  PlanOldPrice,
  PlanMeta,
  PlanPriceDetailsCaption,
  PlanPricingLarge,
  PlanDetailsLarge,
} from "./PlanSelection.styles";

const PlanSelection = ({
  handleSubmit,
  form,
  submitting,
  pristine,
  values,
}) => {
  return (
    <Section>
      <H2>Select a Posting Plan </H2>
      <Row>
        <Col sm4>
          <Fade bottom delay={400} distance="30%">
            <Field
              name="planOption"
              component={SinglePlan}
              planDetail={planOptions.freeYear}
            />
          </Fade>
        </Col>
        <Col sm4>
          <Fade bottom delay={700} distance="30%">
            <Field
              name="planOption"
              component={SinglePlan}
              planDetail={planOptions.silverPlan}
            />
          </Fade>
        </Col>

        <Col sm4>
          <Fade bottom delay={1000} distance="30%">
            <Field
              name="planOption"
              component={SinglePlan}
              planDetail={planOptions.goldPlan}
            />
          </Fade>
        </Col>
      </Row>
      <Fade bottom delay={400} distance="30%">
        <Field
          name="planOption"
          component={SinglePlanLarge}
          planDetail={planOptions.completeMarketingTeam}
        />
      </Fade>
    </Section>
  );
};

const SinglePlan = ({
  planDetail,
  input,
  meta: inputMeta,
}: {
  planDetail: PlanType,
  input: { value: string, onChange: (id: string) => void },
  meta: {
    error: string,
  },
}) => {
  const { name, title, subtitle, planDetails, pricing, meta, id } = planDetail;
  const isSelected = id === input.value;
  return (
    <PlanContainer>
      <PlanButton onClick={() => input.onChange(id)} isSelected={isSelected}>
        <PlanName>
          <PlanRadioButton isSelected={isSelected} /> {name}
        </PlanName>
        <PlanTitle textCenter>{title}</PlanTitle>
        <PlanSubtitle textCenter>{subtitle}</PlanSubtitle>
        <PlanDetails textCenter style={{ maxWidth: 210, margin: "0 auto" }}>
          {planDetails.map((detail, index) => (
            <li key={index}>{detail}</li>
          ))}
        </PlanDetails>
        <PlanPriceDetails textCenter>{pricing.monthly.detail}</PlanPriceDetails>
        <PlanPrice textCenter>
          <PlanOldPrice>{pricing.monthly.old}</PlanOldPrice>
          {pricing.monthly.cost}
        </PlanPrice>
      </PlanButton>
      <PlanMeta textCenter>
        {isSelected ? (
          <H6>
            <Fade bottom distance="30%" cascade>
              <span>
                <Symbol
                  id="selected"
                  maxWidth={13}
                  maxHeight={9}
                  style={{ marginTop: -2 }}
                />{" "}
              </span>
              <span>Selected</span>
            </Fade>
          </H6>
        ) : (
          <H6>{meta}</H6>
        )}
      </PlanMeta>
    </PlanContainer>
  );
};

const SinglePlanLarge = ({
  planDetail,
  input,
  meta: inputMeta,
}: {
  planDetail: PlanType,
  input: { value: string, onChange: (id: string) => void },
  meta: {
    error: string,
  },
}) => {
  const { name, title, subtitle, planDetails, pricing, meta, id } = planDetail;
  const isSelected = id === input.value;
  return (
    <PlanContainer>
      <PlanButton onClick={() => input.onChange(id)} isSelected={isSelected}>
        <PlanName>
          <PlanRadioButton isSelected={isSelected} /> {name}
        </PlanName>
        <PlanTitle>{title} </PlanTitle>
        <PlanSubtitle>{subtitle}</PlanSubtitle>
        <PlanDetailsLarge>
          <PlanDetails>
            {planDetails
              .slice(0, Math.floor(planDetails.length / 2))
              .map((detail, index) => (
                <li key={index}>{detail}</li>
              ))}
          </PlanDetails>
          <PlanDetails>
            {planDetails
              .slice(Math.floor(planDetails.length / 2))
              .map((detail, index) => (
                <li key={index}>{detail}</li>
              ))}
          </PlanDetails>
        </PlanDetailsLarge>
        <PlanPricingLarge>
          <PlanPrice style={{ marginRight: 40, minWidth: 230 }}>
            Custom Pricing
          </PlanPrice>
          <PlanPriceDetailsCaption>
            We'll be in contact to schedule a meeting to review the full details
            of the marketing team plan. In the meantime, we'll get started on
            your 8 posts a month content.
          </PlanPriceDetailsCaption>
        </PlanPricingLarge>
      </PlanButton>
      <PlanMeta
        textCenter
        style={{ display: meta || isSelected ? "block" : "none" }}
      >
        {isSelected ? (
          <H6>
            <Fade bottom distance="30%" cascade>
              <span>
                <Symbol
                  id="selected"
                  maxWidth={13}
                  maxHeight={9}
                  style={{ marginTop: -2 }}
                />{" "}
              </span>
              <span>Selected</span>
            </Fade>
          </H6>
        ) : (
          <H6>{meta}</H6>
        )}
      </PlanMeta>
    </PlanContainer>
  );
};

export default PlanSelection;
