// @flow
import React, { useState } from "react";
import { Field } from "react-final-form";
import {
  // CardNumberElement,
  // CardExpiryElement,
  // CardCVCElement,
  injectStripe,
  Elements,
} from "react-stripe-elements";
import { Row, Col } from "components/styles/layout";
import {
  Label,
  CardNumberElementControl,
  CardExpiryElementControl,
  CardCVCElementControl,
  stripeElementStyle,
} from "components/styles/form";
import { H3, H6, Caption } from "components/styles/type";
import { colors } from "components/styles/variables";
import { ToggleButton, LargeButton } from "components/styles/buttons";
import { UtilDiv } from "components/styles/utils";
import Symbol from "components/symbols/Symbol";
import {
  ToggleSection,
  PlanSelectionContainer,
  CardContainer,
  CheckoutButtonContainer,
  FeedbackContainer,
  FeedbackItem,
} from "./CheckoutStep.style";
import { Section } from "./SMM.styles";

import {
  PlanPriceDetails,
  PlanOldPrice,
  PlanPrice,
} from "./PlanSelection.styles";

import planOptions from "./planOptions";
import type { PlanType } from "./PlanSelection.types";

import { handleSMMOffer } from "src/firebase/firebase.fn";
import { navigate } from "gatsby";
const CheckoutStep = props => {
  return (
    <Section style={{ marginBottom: 0 }}>
      <Elements>
        <Checkout {...props} />
      </Elements>
    </Section>
  );
};

const Checkout = injectStripe(({ stripe, form }) => {
  const [feedback, setFeedback] = useState([]);
  const [status, setStatus] = useState({ idle: true });

  return (
    <div>
      <div style={{ marginBottom: 40 }}>
        <Field name="planOption">
          {({ input }) => (
            <div>
              {input.value === "freeYear" ? (
                <FreeYearLayout planOptionValue={input.value} />
              ) : (
                <PaymentLayout planOptionValue={input.value} />
              )}
              <CheckoutButtonContainer>
                {feedback.length > 0 && (
                  <FeedbackContainer>
                    <p>
                      Please fix the following issues and try the submission
                      button again:
                    </p>
                    {feedback.map((f, i) => (
                      <FeedbackItem key={i}>{f}</FeedbackItem>
                    ))}
                  </FeedbackContainer>
                )}
                <LargeButton
                  onClick={handleCheckoutButton(
                    form,
                    stripe,
                    setFeedback,
                    status,
                    setStatus
                  )}
                >
                  {status.submitting
                    ? "Submitting..."
                    : checkoutButtonText(input.value)}
                </LargeButton>
              </CheckoutButtonContainer>
            </div>
          )}
        </Field>
      </div>
    </div>
  );
});

const checkoutButtonText = planOptionValue => {
  switch (planOptionValue) {
    case "freeYear":
      return "Start My Free Year Plan";
    case "silverPlan":
      return "Start My Silver Plan";
    case "goldPlan":
      return "Start My Gold Plan";
    case "completeMarketingTeam":
      return "Start My Complete Marketing Team Plan";
    default:
      return "Start My Plan";
  }
};

const handleCheckoutButton = (
  form,
  stripe,
  setFeedback,
  status,
  setStatus
) => e => {
  e.preventDefault();
  if (status.submitting) {
    return;
  }
  const { values, errors } = form.getState();

  const errorMessages = Object.values(errors);
  if (errorMessages.length > 0) {
    form.submit();
    setFeedback(errorMessages);
    return;
  }
  if (values.planOption === "freeYear") {
    setFeedback([]);
    // Axios post
    setStatus({ submitting: true });
    handleSMMOffer(values)
      .then(() => {
        // console.log(res);
        if (window && window.fbq) {
          window.fbq("track", "Purchase", {
            value: 1,
            currency: "USD",
          });
        }

        navigate("/offers/smm/confirm");
      })
      .catch(error => {
        setStatus({ idle: true });
        let message =
          error && error.message ? error.message : "An Error Occured";
        setFeedback([message]);
      });
  } else {
    setStatus({ submitting: true });
    setFeedback([]);
    stripe
      .createToken({})
      .then(token => {
        if (token.error) {
          setFeedback([token.error.message]);
          setStatus({ idle: true });
        } else {
          // Axios post
          let withToken = Object.assign({}, values, token);
          handleSMMOffer(withToken)
            .then(() => {
              if (window && window.fbq) {
                const plan = planOptions[values.planOption];
                const pricing = plan ? plan.pricing[values.billingCycle] : 0;
                const value = pricing ? pricing.chargeCost : 0;

                window.fbq("track", "Purchase", {
                  value: value,
                  currency: "USD",
                });
              }
              navigate("/offers/smm/confirm");
            })
            .catch(error => {
              setStatus({ idle: true });
              let message =
                error && error.message ? error.message : "An Error Occured";
              setFeedback([message]);
            });
        }
      })
      .catch(err => console.error(err));
  }
};

const FreeYearLayout = ({ planOptionValue }) => (
  <Summary planOptionValue={planOptionValue} />
);

const PaymentLayout = ({ planOptionValue }) => (
  <Row middle>
    <Col sm6>
      <Summary planOptionValue={planOptionValue} />
    </Col>
    <Col sm6>
      <Card />
    </Col>
  </Row>
);

const Summary = ({ planOptionValue }) => {
  const plan = planOptions[planOptionValue];
  return (
    <PlanSelectionContainer>
      <H6 style={{ marginBottom: 20, color: colors.grayDark }}>Summary</H6>
      {plan.id !== "freeYear" && (
        <Field name="billingCycle" component={BillingCycleToggle} plan={plan} />
      )}
      <Field name="billingCycle">
        {({ input }) => (
          <div
            style={{
              maxWidth: 230,
              margin: "0 auto",
              paddingTop: 10,
              borderTop: "1px solid " + colors.gray,
            }}
          >
            <H3 style={{ marginBottom: 5 }}>{plan.name} Selected</H3>
            {plan.id !== "completeMarketingTeam" && <p>{plan.title}</p>}
            {plan.id === "completeMarketingTeam" && (
              <Caption>
                We'll be in contact to schedule a meeting to review the full
                details of the marketing team plan. In the meantime, we'll get
                started on your 8 post a month content.
              </Caption>
            )}
            <div style={{ marginBottom: 10, color: colors.grayDark }}>
              <strong>Today's Total</strong>
            </div>
            {plan.pricing[input.value].detail && (
              <PlanPriceDetails>
                {plan.pricing[input.value].detail}
              </PlanPriceDetails>
            )}
            <PlanPrice style={{ marginBottom: 20 }}>
              {plan.pricing[input.value].old && (
                <PlanOldPrice>{plan.pricing[input.value].old}</PlanOldPrice>
              )}{" "}
              {plan.pricing[input.value].cost}
            </PlanPrice>
          </div>
        )}
      </Field>
    </PlanSelectionContainer>
  );
};

const BillingCycleToggle = ({ input, plan }) => {
  const toggleActive = input.value === "annually";
  return (
    <div>
      <strong style={{ color: colors.grayDark }}>Billing Cycle</strong>
      <ToggleSection>
        <strong
          style={{ opacity: toggleActive ? 0.5 : 1 }}
          onClick={() => input.onChange("monthly")}
        >
          Monthly
        </strong>
        <ToggleButton
          active={toggleActive}
          onClick={() => input.onChange(toggleActive ? "monthly" : "annually")}
        />
        <strong
          style={{ opacity: toggleActive ? 1 : 0.5 }}
          onClick={() => input.onChange("annually")}
        >
          Annually
        </strong>
      </ToggleSection>
      <div style={{ maxWidth: 230, margin: "0 auto" }}>
        <div style={{ color: colors.primary, marginBottom: 20 }}>
          <strong>{plan.pricing[input.value].savings}</strong>
        </div>
      </div>
    </div>
  );
};

const Card = () => {
  return (
    <div>
      <CardContainer>
        <H6 textCenter style={{ marginBottom: 20 }}>
          Credit Card Information
        </H6>
        <StripeCard />
      </CardContainer>
      <div style={{ marginTop: 20 }}>
        <Row>
          <Col sm6>
            <Caption>
              <Symbol
                id="lock"
                maxWidth={14}
                maxHeight={14}
                style={{ verticalAlign: "top" }}
              />{" "}
              256 Bit SSL Secure Certficate
            </Caption>
          </Col>
          <Col sm6>
            <UtilDiv textRightSm>
              <Caption>
                Powered By <Symbol id="stripe" maxWidth={50} maxHeight={20} />
              </Caption>
            </UtilDiv>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const StripeCard = () => {
  return (
    <form>
      <div className="cart-summary__card">
        <div style={{ marginBottom: 20 }}>
          <Label htmlFor="cardNumber">Credit Card Number</Label>
          <CardNumberElementControl id="cardNumber" {...stripeElementStyle} />
        </div>
        <Row>
          <Col sm6>
            <div style={{ marginBottom: 20 }}>
              <Label htmlFor="">CVC Code</Label>
              <CardCVCElementControl {...stripeElementStyle} />
            </div>
          </Col>
          <Col sm6>
            <Label htmlFor="">Expiration date</Label>
            <CardExpiryElementControl {...stripeElementStyle} />
          </Col>
        </Row>
      </div>
    </form>
  );
};

export default CheckoutStep;
