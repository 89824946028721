// @flow
import React from "react";
import { Label } from "components/styles/form";
import { Caption } from "components/styles/type";
import { Button } from "components/styles/buttons";
import InputField from "./InputField";
import Symbol from "components/symbols/Symbol";

import { FieldArray } from "react-final-form-arrays";
import camelCase from "components/utils/camelCase";

type PropType = {
  label: string,
  name?: string,
  component: React$Component<any>,
  placeholder?: string,
  helper?: string,
};

const InputFieldArray = ({
  label,
  name: arrayName,
  placeholder,
  component: InputComponent,
  helper,
}: PropType) => {
  if (!arrayName) arrayName = camelCase(label);
  return (
    <FieldArray name={arrayName}>
      {({ fields }) => (
        <div style={{ marginBottom: 40 }}>
          <div>
            <Label>{label}</Label>
          </div>
          {fields.map((name, index) => (
            <div key={index} style={{ position: "relative", paddingRight: 40 }}>
              <InputField
                name={name + ".description"}
                placeholder={placeholder}
                component={InputComponent}
                formGroupStyle={{ marginBottom: 10 }}
              />
              {index === 0 ? null : (
                <Button
                  text
                  onClick={() => fields.remove(index)}
                  style={{ position: "absolute", right: 5, top: 5 }}
                >
                  <Symbol id="remove" maxWidth={20} maxHeight={20} />
                </Button>
              )}
            </div>
          ))}
          {helper && <Caption>{helper}</Caption>}
          {(!fields.length || fields.length < 4) && (
            <Button text onClick={() => fields.push()}>
              + Add Another
            </Button>
          )}
        </div>
      )}
    </FieldArray>
  );
};

export default InputFieldArray;
