// @flow
import styled, { css, keyframes } from "styled-components";
import Symbol from "components/symbols/Symbol";
import { mediaQueries } from "components/styles/variables";

const fadeIn = keyframes`
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const rubberBand = keyframes`
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
`;

export const BgSymbolContainer = styled.div`
  opacity: 0.15;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 500px;
  overflow: hidden;

  ${mediaQueries.smMin`
    opacity: 1;
  `}
`;

export const BgSymbol = styled(Symbol)`
  fill: green;
  position: absolute;
  z-index: 0;
  opacity: 0;
  animation: ${fadeIn} 1s cubic-bezier(0.41, 0.17, 0.18, 1.04) 1 forwards;
`;
