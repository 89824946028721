// @flow
import React, { cloneElement } from "react";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import validation from "./validation";

type PropType = {
  children: React$Node,
};

const initialValues = {
  offers: [{}],
  planOption: "freeYear",
  billingCycle: "monthly",
};
// const initialValues = {
//   logoFile:
//     "https://res.cloudinary.com/hotfix/image/upload/v1558642527/smm/ibgnqix8e0mz4tunwfqn.jpg",
//   businessName: "Buiz Name",
//   description: "Super decription",
//   industry: "Cleaning",
//   foundingDate: "",
//   website: "Website",
//   address: "Super address",
//   primaryColor: "#c45100",
//   offers: [{}],
//   contactFirstName: "Kris",
//   contactLastName: "Amabor",
//   contactEmail: "chris@chrisamador.me",
//   contactPhone: "7090909",
//   billingCycle: "monthly",
//   planOption: "silverPlan",
// };

const SMMForm = ({ children }: PropType) => {
  return (
    <div>
      <Form
        onSubmit={() => {}}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={initialValues}
        validate={validation}
        subscription={{ submitting: true, pristine: true }}
      >
        {({ handleSubmit, form, submitting, pristine, values }) =>
          React.Children.map(children, child =>
            cloneElement(child, {
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
            })
          )
        }
      </Form>
    </div>
  );
};

// const FormRender = ({ handleSubmit, form, submitting, pristine, values }) => ()

export default SMMForm;
