// @flow

import styled, { css } from "styled-components";

import {
  spacing,
  borderRadius,
  colors,
  fontSize,
} from "components/styles/variables";
export const PlanSelectionContainer = styled.div`
  text-align: center;
`;

export const ToggleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${spacing.medium}px;
  margin-bottom: ${spacing.medium}px;
`;

export const CardContainer = styled.div`
  border: 1px solid ${colors.gray};
  border-radius: ${borderRadius.base}px;
  padding: ${spacing.medium}px;
`;

export const CheckoutButtonContainer = styled.div`
  margin-top: ${spacing.large}px;
  text-align: center;
`;

export const FeedbackContainer = styled.div`
  max-width: 350px;
  margin: 0 auto;
  margin-bottom: ${spacing.medium}px;
`;

export const FeedbackItem = styled.div`
  font-size: ${fontSize.base - 2}px;
  color: ${colors.redDark};
  background-color: #ffe1e0;
  border: 1px solid ${colors.redLight};
  padding: ${spacing.xsmall}px;
  margin-bottom: ${spacing.xsmall}px;
`;
