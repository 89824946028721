// @flow
import styled, { css } from "styled-components";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from "react-stripe-elements";

import {
  spacing,
  colors,
  mediaQueries,
  borderRadius,
  boxShadow,
  fonts,
  fontSize,
} from "./variables";

import { UtilsStyles } from "./utils";

export const FormGroup = styled.div`
  margin-bottom: ${spacing.medium}px;
  position: relative;
  ${mediaQueries.smMin`
    margin-bottom: ${spacing.xlarge}px;
  `}
`;

export const Label = styled.label`
  font-weight: bold;
`;

export const Control = css`
  border-radius: ${borderRadius.small}px;
  border: 1px solid ${colors.gray};
  background: white;
  width: 100%;
  padding: ${spacing.xsmall}px ${spacing.small}px;
`;

export const InputText = styled.input`
  ${Control}
`;

export const InputTextArea = styled.textarea`
  ${Control}
  min-height: 125px;
`;

export const CardNumberElementControl = styled(CardNumberElement)`
  ${Control}
`;

export const CardExpiryElementControl = styled(CardExpiryElement)`
  ${Control}
`;

export const CardCVCElementControl = styled(CardCVCElement)`
  ${Control}
`;

export const stripeElementStyle = {
  style: {
    base: {
      fontSize: fontSize.base + "px",
      color: colors.black,
      fontFamily: fonts.base,
      "::placeholder": {
        color: colors.gray,
      },
    },
    invalid: {
      color: colors.redLight,
    },
  },
};

export const InputFile = styled.input`
  ${UtilsStyles.srOnly}
`;

export const FileUploadLabel = styled.label`
  display: block;
  background: ${colors.grayLight};
  border: 1px solid ${colors.gray};
  border-radius: ${borderRadius.base}px;
  height: 190px;
  margin: 0;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  transition: background 500ms ease;

  &:hover,
  &:focus {
    background: #e7e7e7;
  }
`;

export const FileUploadLabelContent = styled.span`
  display: block;
  width: 100%;
`;
export const ImgPreview = styled.img`
  max-width: 100%;
  border: 1px solid ${colors.gray};
  margin-bottom: ${spacing.medium}px;
`;

export const DropdownOptions = styled.div`
  background: white;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 4;
  box-shadow: ${boxShadow.base};
`;

export const DropdownOption = styled.div`
  padding: ${spacing.xsmall}px ${spacing.small}px;
  background: ${props => (props.highlight ? colors.redLight : "white")};
  transition: background 250ms ease-out;
  font-weight: ${props => (props.selected ? "bold" : "normal")};
`;

export const DropdownToggleBtn = styled.button`
  position: absolute;
  right: 15px;
  top: 15px;
  border: none;
  background: transparent;
  padding: 0;
  height: 16px;
  width: 16px;
  cursor: pointer;
`;

export const ColorOverlay = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  background: white;
`;

export const ErrorMessage = styled.div`
  border-radius: ${borderRadius.small}px;
  font-size: ${fontSize.base - 2}px;
  color: ${colors.redDark};
  background-color: #ffe1e0;
  border: 1px solid ${colors.redLight};
  padding: ${spacing.xsmall}px;
  margin-bottom: ${spacing.xsmall}px;
`;
