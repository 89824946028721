// @flow
import React, { useState } from "react";
import {
  InputText,
  Label,
  FormGroup,
  ColorOverlay,
  ErrorMessage,
} from "components/styles/form";
import { Caption } from "components/styles/type";
import { CompactPicker } from "react-color";

const InputColor = ({ label, name, input, meta, placeholder, helper }) => {
  // if (!name) name = camelCase(label);
  const [viewPicker, setViewPicker] = useState(false);
  const [formElm, setFormElm] = useState(null);

  const showPicker = () => {
    setViewPicker(true);
  };

  const hidePicker = () => {
    setViewPicker(false);
  };

  // const togglePicker = () => {
  //   setViewPicker(!viewPicker);
  // };

  const handleChange = color => {
    input.onChange(color.hex);
    hidePicker();
  };

  return (
    <FormGroup ref={node => setFormElm(node)}>
      <div>
        <Label htmlFor={name}>{label}</Label>
        <div style={{ position: "relative", marginBottom: 10 }}>
          <ColorOverlay
            style={{ background: input.value }}
            onClick={showPicker}
          />
          <InputText
            {...input}
            placeholder={placeholder}
            id={name}
            onFocus={showPicker}
            onBlur={hidePicker}
          />
        </div>
        {meta.touched && meta.error ? (
          <ErrorMessage>{meta.error}</ErrorMessage>
        ) : null}
        {helper && <Caption>{helper}</Caption>}
        {viewPicker && (
          <div style={{ position: "absolute", top: 92, zIndex: 1 }}>
            <div style={cover} onClick={hidePicker} />
            <CompactPicker
              onChangeComplete={handleChange}
              color={input.value}
              width={formElm && formElm.offsetWidth + "px"}
            />
          </div>
        )}
      </div>
    </FormGroup>
  );
};

const cover = {
  position: "fixed",
  top: "0px",
  right: "0px",
  bottom: "0px",
  left: "0px",
};

export default InputColor;
