// @flow
import React from "react";
import Fade from "react-reveal/Fade";

import Symbol from "components/symbols/Symbol";
import { colors } from "components/styles/variables";
import { SMMHeader, SMMH1, SMMH1Tag } from "./SMM.styles";
import { H6 } from "components/styles/type";

import SymbolBg from "./SymbolBg";

const Header = () => {
  return (
    <div>
      <SymbolBg />
      <SMMHeader>
        <Fade bottom delay={400} distance="30%" cascade>
          <SMMH1>
            Free Year Of
            <br />
            <SMMH1Tag>Social Media Marketing</SMMH1Tag>
          </SMMH1>
          <H6 style={{ color: colors.grayDark }}>
            Powered By:{" "}
            <Symbol
              id="logo-text"
              fill={colors.grayDark}
              maxWidth={60}
              maxHeight={18}
              style={{ marginTop: -8 }}
            />
          </H6>
        </Fade>
      </SMMHeader>
    </div>
  );
};

export default Header;
