// @flow
import React from "react";
import { Row, Col } from "components/styles/layout";
import { H2, H3 } from "components/styles/type";
import { Section } from "./SMM.styles";

import InputField from "components/form/InputField";
import InputFieldArray from "components/form/InputFieldArray";
import InputDropdown from "components/form/InputDropdown";
import InputDate from "components/form/InputDate";
import InputColor from "components/form/InputColor";
import InputImageFile from "components/form/InputImageFile";

import { InputText, InputTextArea } from "components/styles/form";
import { Field, FormSpy } from "react-final-form";

import Fade from "react-reveal/Fade";

const industryOptions = [
  { value: "Agriculture" },
  { value: "Cleaning Services" },
  { value: "Construction" },
  { value: "eCommerce" },
  { value: "Financial Services" },
  { value: "Information Technology" },
  { value: "Insurance" },
  { value: "Legal Services" },
  { value: "Other" },
  { value: "Painting Services" },
  { value: "Real Estate" },
  { value: "Repair Services" },
  { value: "Retail" },
  { value: "Startup" },
];

const BusinessDetails = ({
  handleSubmit,
  form,
  submitting,
  pristine,
  values,
}) => (
  <Section>
    <Fade bottom delay={400} distance="150px">
      <div>
        <H2>Business Details</H2>
        <Row>
          <Col sm4>
            <Field
              name="logoFile"
              label="Logo Upload *"
              component={InputImageFile}
            />
          </Col>
          <Col sm8>
            <Row>
              <Col sm8>
                <InputField
                  name="businessName"
                  label="Business Name *"
                  component={InputText}
                />
              </Col>
              <Col sm4>
                <Field
                  name="industry"
                  label="Industry *"
                  items={industryOptions}
                  component={InputDropdown}
                />
              </Col>
            </Row>
            <Row>
              <Col sm8>
                <InputField
                  name="description"
                  label="Description *"
                  component={InputTextArea}
                  helper="A few sentences about your business. Anything you would like your clients to know."
                />
              </Col>
              <Col sm4>
                <Field
                  name="foundingDate"
                  label="Founding Date *"
                  component={InputDate}
                  helper="The first day of your business."
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm4>
            <InputField
              name="website"
              label="Website *"
              component={InputText}
              helper="Enter N/A if you don't yet have a website."
            />
          </Col>
          <Col sm5>
            <InputField
              name="address"
              label="Address *"
              component={InputTextArea}
              helper="The complete mailing address for your business"
            />
          </Col>
          <Col sm3>
            <Field
              name="primaryColor"
              label="Primary Color *"
              component={InputColor}
              helper="Your primary brand color. Enter the specific hex color code if applicable."
            />
          </Col>
        </Row>
        <Row>
          <Col sm6>
            <InputFieldArray
              name="offers"
              label="Upcoming Sales or Offers"
              component={InputTextArea}
              helper="Enter any upcoming promotions, offers, sales, etc. (Optional)"
            />
          </Col>
        </Row>
        <H3>Your Contact Information</H3>
        <Row>
          <Col sm3>
            <InputField
              name="contactFirstName"
              label="First Name *"
              component={InputText}
            />
          </Col>
          <Col sm3>
            <InputField
              name="contactLastName"
              label="Last Name *"
              component={InputText}
            />
          </Col>
          <Col sm3>
            <InputField
              name="contactEmail"
              label="Email *"
              component={InputText}
              type="email"
            />
          </Col>
          <Col sm3>
            <InputField
              name="contactPhone"
              label="Phone *"
              component={InputText}
              type="phone"
            />
          </Col>
        </Row>
      </div>
    </Fade>
  </Section>
);

export default BusinessDetails;
