// @flow
import { Post, Get } from "./utils/axios.config";
import type { AxiosPromise } from "axios";

// End Point Url
let url;

if (process.env.NODE_ENV === "development") {
  url =
    "http://localhost:5000/" +
    process.env.GATSBY_FIREBASE_PROJECTID +
    "/us-central1";
}

if (process.env.NODE_ENV === "production") {
  url =
    "https://us-central1-" +
    process.env.GATSBY_FIREBASE_PROJECTID +
    ".cloudfunctions.net";
}

/**
 *
 * Hello World
 *
 */

export const handleSMMOffer = (data: any): AxiosPromise<{ data: string }> =>
  Post(url + "/handleSMMOffer", data);
