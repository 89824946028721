// @flow
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "./inputDate.css";
import styled from "styled-components";
import {
  Control,
  Label,
  FormGroup,
  ErrorMessage,
} from "components/styles/form";
import { Caption } from "components/styles/type";

// type PropType = {
//   label?: string,
//   name?: string,
//   component: React$Component<any>,
//   placeholder?: string,
// };

const DatePickerStyled = styled(DatePicker)`
  ${Control}
  margin-bottom: 10px;
`;

const InputDate = ({ label, name, input, meta, helper }) => {
  // if (!name) name = camelCase(label);
  return (
    <FormGroup>
      <div>
        <Label htmlFor={name}>{label}</Label>
      </div>
      <DatePickerStyled
        {...input}
        selected={input.value}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        todayButton="Today"
        endDate={new Date()}
        autocomplete="off"
      />
      {meta.touched && meta.error ? (
        <ErrorMessage>{meta.error}</ErrorMessage>
      ) : null}
      {helper && <Caption>{helper}</Caption>}
    </FormGroup>
  );
};

export default InputDate;
