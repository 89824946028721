// @flow

import styled from "styled-components";

export const ImgResponsive = styled.img`
  max-width: 100%;
`;

export const ImgBgResponsive = styled.div`
  background-size: cover;
  min-height: 200px;
  background-position: center;
  margin-bottom: 20px;
`;
