// @flow
import React, { useEffect } from "react";
import { H1, H5, H6, H2 } from "components/styles/type";
import { UtilDiv } from "components/styles/utils";
import { colors } from "components/styles/variables";
import { SMMPage } from "./SMM.styles";
import getURLParam from "components/utils/getURLParam";
import { navigate } from "gatsby";
type PropType = {};

const SmmConfirm = props => {
  // let emailParam = getURLParam("email");
  // useEffect(() => {
  //   if (!emailParam) {
  //     navigate("/offers/smm");
  //   }
  // }, []);
  // if (!emailParam) return null;
  return (
    <SMMPage>
      <UtilDiv textCenter>
        <H6>Thank you</H6>
        <H2 style={{ color: colors.primary }}>
          Social Media Marketing Confirmed 🎉
        </H2>
        <p>
          Thank you for your submission. We'll be in contact soon with your
          postings to be approved. <br /> If you have any questions please reach
          out anytime to{" "}
          <a href="mailto:support@hotfix.studio">support@hotfix.studio</a>
        </p>
      </UtilDiv>
    </SMMPage>
  );
};

export default SmmConfirm;
