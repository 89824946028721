// @flow
import React, { useState } from "react";
import {
  InputFile,
  Label,
  FormGroup,
  FileUploadLabel,
  FileUploadLabelContent,
  ImgPreview,
  ErrorMessage,
} from "components/styles/form";
import { H5, Caption } from "components/styles/type";
import { colors } from "components/styles/variables";
import Symbol from "components/symbols/Symbol";
import uploadToCloudinary from "components/utils/uploadToCloudinary";

const InputImageFile = ({ label, name, input, meta, placeholder }) => {
  // if (!name) name = camelCase(label);
  const [uploading, setUploading] = useState(false);
  const handleFileSelect = (e: SyntheticInputEvent<HTMLInputElement>) => {
    e.preventDefault();
    let files = e.target.files;

    if (uploading) {
      return;
    }

    if (!files.length) {
      return input.onChange("@error/nofile");
    }

    if (
      !(
        files[0].type == "image/jpeg" ||
        files[0].type == "image/jpg" ||
        files[0].type == "image/png"
      )
    ) {
      return input.onChange("@error/wrongformat");
    }

    setUploading(true);

    uploadToCloudinary(files[0])
      .then(res => {
        input.onChange(res.data.secure_url);
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const validImage = input.value && !meta.error;

  return (
    <FormGroup>
      <div style={{ marginBottom: 10 }}>
        <Label htmlFor={name}>{label}</Label>
        {validImage && <ImgPreview src={input.value} />}
        <FileUploadLabel
          htmlFor={name}
          style={{ height: validImage ? 60 : null }}
        >
          <FileUploadLabelContent>
            {uploading ? (
              <UploadingText />
            ) : (
              <UploadInput
                name={name}
                handleFileSelect={handleFileSelect}
                validImage={validImage}
              />
            )}
          </FileUploadLabelContent>
        </FileUploadLabel>
      </div>
      {meta.touched && meta.error ? (
        <ErrorMessage>{meta.error}</ErrorMessage>
      ) : null}
      <Caption>We'll use your logo in the graphics.</Caption>
    </FormGroup>
  );
};

const UploadInput = ({ name, handleFileSelect, validImage }) => (
  <span>
    <span>
      <InputFile type="file" id={name} onChange={handleFileSelect} />
    </span>
    <Symbol
      id="upload"
      maxWidth={18}
      maxHeight={18}
      style={{ marginBottom: 10, marginRight: validImage ? 10 : 0 }}
    />
    <H5 style={{ display: validImage ? "inline-block" : "block" }}>
      {validImage ? "Upload A Different Logo File" : "Upload Your Logo File"}
    </H5>
    {validImage ? null : (
      <Caption style={{ color: colors.grayDark }}>PNG or JPEG only</Caption>
    )}
  </span>
);

const UploadingText = () => <H5>Uploading...</H5>;

export default InputImageFile;

// <InputText {...input} placeholder={placeholder} id={name} />
