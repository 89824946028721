// @flow
import React from "react";
import Fade from "react-reveal/Fade";

import { Row, Col } from "components/styles/layout";
import { ImgBgResponsive } from "components/styles/images";
import { Section } from "./SMM.styles";
import { H2, Em, Caption } from "components/styles/type";
import { UtilDiv } from "components/styles/utils";

import smmApprove from "../../../assets/images/smm-approve.jpg";
import smmCustom from "../../../assets/images/smm-custom.jpg";

const HowItWorks = () => {
  return (
    <Section>
      <H2>How it works</H2>
      <Row>
        <Col sm6>
          <p>
            Begin by entering basic details about your business in the form
            below. Tell us about your business and upload a logo so we can
            create <Em>content that fits your business</Em>.
          </p>
          <p>
            Once we received your business details, we create a 12-month posting
            schedule and create the content, <Em>copy and graphics</Em>! This
            takes between 1 - 3 business days. You will receive an email once
            the schedule and content are ready for your approval.
          </p>
        </Col>
        <Col sm6>
          <Fade bottom delay={400} distance="30%" fraction={0.8}>
            <ImgBgResponsive
              style={{
                border: "1px solid #ec302d",
                boxShadow: "6px 6px #ec302d",
                backgroundSize: "120%",
                backgroundImage: `url(${smmCustom})`,
              }}
            />
            <UtilDiv textCenterSm style={{ marginBottom: 40 }}>
              <Caption>
                Get custom-made postings for your business automatically posted
                for you.
              </Caption>
            </UtilDiv>
          </Fade>
        </Col>
      </Row>
      <Row reverseSm>
        <Col sm6>
          <p>
            You approve the schedule and content or{" "}
            <Em>if needed, edit anything</Em> right on the approval page. You
            can edit the content, swap images, and even change the post date.
            Once everything is approved and we have guest access to your
            accounts, the schedule takes effect! Your posts go up automatically
            without you having to do anything else.
          </p>
          <p>
            That’s it, you’ll have effective marketing postings on your social
            media accounts for <Em>an entire year in a few minutes </Em>of your
            time. Best of all, <Em>FREE!</Em> You don't have anything to lose.
          </p>
        </Col>
        <Col sm6>
          <Fade bottom delay={400} distance="30%" fraction={0.8}>
            <ImgBgResponsive
              style={{
                border: "1px solid #ec302d",
                boxShadow: "6px 6px #ec302d",
                backgroundSize: "120%",
                backgroundImage: `url(${smmApprove})`,
              }}
            />
            <UtilDiv textCenterSm style={{ marginBottom: 40 }}>
              <Caption>
                Approve or edit the content before anything goes up.
              </Caption>
            </UtilDiv>
          </Fade>
        </Col>
      </Row>
    </Section>
  );
};

export default HowItWorks;
