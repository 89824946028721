// @flow
import styled from "styled-components";
import Symbol from "components/symbols/Symbol";
import { spacing, colors, mediaQueries } from "components/styles/variables";

export const SqueezeHeaderStyle = styled.header`
  padding: ${spacing.large}px 0;
`;

export const TagLine = styled.div`
  color: ${colors.grayDark};
  display: inline-block;
  vertical-align: middle;
  max-width: 190px;

  ${mediaQueries.smMin`

  `}
`;

export const HeaderSymbol = styled(Symbol)`
  margin-right: ${spacing.medium}px;
  max-width: 100px;
  max-height: 35px;

  ${mediaQueries.smMin`
    margin-right: ${spacing.large}px;
    max-width: 130px;
    max-height: 40px;
  `}
`;
