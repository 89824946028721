// @flow
import React from "react";
import SqueezePage from "components/templates/SqueezePage";
import { Container } from "components/styles/layout";
import { SMMPage } from "./SMM.styles";
import { Router, Link } from "@reach/router";

// import { H2, H6, Lead, Em, Caption } from "components/styles/type";
// import { Button } from "components/styles/buttons";

import Header from "./Header";
import Welcome from "./Welcome";
import HowItWorks from "./HowItWorks";
import BusinessDetails from "./BusinessDetails";
import PlanSelection from "./PlanSelection";
import CheckoutStep from "./CheckoutStep";
import SMMForm from "./SMMForm";
import SMMConfirm from "./SMMConfirm";

const SMM = () => (
  <SqueezePage>
    <Container>
      <Router>
        <SMMPageForm path="/offers/smm" />
        <SMMConfirm path="/offers/smm/confirm" />
      </Router>
    </Container>
  </SqueezePage>
);

const SMMPageForm = () => (
  <SMMPage>
    <Header />
    <Welcome />
    <HowItWorks />
    <SMMForm>
      <BusinessDetails />
      <PlanSelection />
      <CheckoutStep />
    </SMMForm>
  </SMMPage>
);

export default SMM;
