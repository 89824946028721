// @flow

import axios from "axios";
import type { AxiosPromise } from "axios";

export const Get = (url: string): AxiosPromise<any> =>
  axios.get(url).catch(error => {
    throw error;
  });

export const Post = (url: string, data: any): AxiosPromise<any> =>
  axios.post(url, data).catch(error => {
    throw error;
  });

export const Put = (url: string, data: any): AxiosPromise<any> =>
  axios.put(url, data).catch(error => {
    throw error;
  });

export const Patch = (url: string, data: any): AxiosPromise<any> =>
  axios.patch(url, data).catch(error => {
    throw error;
  });

export const Options = (url: string): AxiosPromise<any> =>
  axios.options(url).catch(error => {
    throw error;
  });

export const Delete = (url: string): AxiosPromise<any> =>
  axios.delete(url).catch(error => {
    throw error;
  });
