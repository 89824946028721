// @flow
import styled from "styled-components";
import {
  spacing,
  borderRadius,
  boxShadow,
  fontSize,
  mediaQueries,
} from "components/styles/variables";
import { H1 } from "components/styles/type";

export const SMMPage = styled.div`
  background: white;
  padding: ${spacing.medium}px;
  border-radius: ${borderRadius.base}px;
  box-shadow: ${boxShadow.base};
  position: relative;
  /* overflow: hidden; */
  margin-bottom: ${spacing.large}px;

  ${mediaQueries.smMin`
    padding: ${spacing.xlarge}px;
  `}
`;

export const SMMHeader = styled.div`
  text-align: center;
  padding-top: ${spacing.medium}px;
  margin-bottom: ${spacing.xlarge * 2}px;
  position: relative;
`;

export const SMMH1 = styled(H1)`
  text-transform: uppercase;
  letter-spacing: 10px;
  line-height: 1.15;
  margin-bottom: ${spacing.medium}px;
`;

export const SMMH1Tag = styled.span`
  text-transform: none;
  letter-spacing: 2px;
  font-size: ${fontSize.h1 * 0.75}px;
`;

export const Section = styled.section`
  margin-bottom: ${spacing.xlarge * 2}px;
`;
